import * as React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import logo from "../../images/logo-name-white.svg";
import { Container } from "@material-ui/core";

const Header = ({ siteTitle }) => (
  <AppBar position="sticky" style={{ minWidth: "300px" }}>
    <Container maxWidth="xl">
      <Toolbar>
        <div
          style={{
            display: "flex",
            flexGrow: 1,
            alignItems: "center",
          }}
        >
          <img src={logo} style={{ maxWidth: "10em" }} alt="Fleet Viper logo" />
        </div>
        <a href={process.env.GATSBY_WEBAPP_URL} style={{ color: "white" }}>
          <Button color="inherit">Login</Button>
        </a>
      </Toolbar>
    </Container>
  </AppBar>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: "FleetViper",
};

export default Header;
