import * as React from "react";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { useForm } from "@formspree/react";
import theme from "../theme";

import { buttoncontainer, cardcontainer, container } from "./styles.module.css";
import { Card, CardContent, CardHeader, Toolbar } from "@material-ui/core";

const Submitted = () => (
  <div id="contact" className={container}>
    <div className={cardcontainer}>
      <Card style={{ backgroundColor: "rgba(255,255,255,0.5)" }}>
        <CardHeader
          title="Request submitted"
          subheader="Thanks for your interest.  We’ll get back to you as soon as possible"
        ></CardHeader>
      </Card>
    </div>
  </div>
);

const Contact = () => {
  const [state, handleSubmit] = useForm("mbjqaeww");

  if (state.succeeded) {
    return <Submitted />;
  }

  return (
    <div id="contact" className={container}>
      <div className={cardcontainer}>
        <Card style={{ backgroundColor: "rgba(255,255,255,0.7)" }}>
          <CardHeader
            title="Interested to hear more?"
            subheader="Just fill out the form, and we’ll get back to you as soon as possible"
            titleTypographyProps={{
              style: {
                paddingBottom: "0.2em",
                color: theme.palette.primary.dark,
              },
            }}
            subheaderTypographyProps={{ color: "inherit" }}
          ></CardHeader>
          <CardContent>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} md={6}>
                  <TextField
                    label="Name"
                    variant="outlined"
                    name="name"
                    type="text"
                    placeholder="Enter your name"
                    data-cy="name"
                    inputProps={{ "aria-label": "Your name" }}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Company"
                    variant="outlined"
                    name="company"
                    type="text"
                    placeholder="Enter company name"
                    data-cy="company"
                    inputProps={{ "aria-label": "Company name" }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Email"
                    variant="outlined"
                    name="email"
                    type="text"
                    placeholder="Enter email"
                    data-cy="email"
                    inputProps={{ "aria-label": "Contact email address" }}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Telephone"
                    variant="outlined"
                    name="telephone"
                    type="text"
                    placeholder="Enter telephone"
                    data-cy="telephone"
                    inputProps={{ "aria-label": "Contact telephone number" }}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <div className={buttoncontainer}>
                <Button
                  type="submit"
                  variant="contained"
                  color="secondary"
                  size="large"
                  disabled={state.submitting}
                >
                  Send
                </Button>
              </div>
            </form>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

Contact.propTypes = {};

export default Contact;
