import * as React from "react";
import PropTypes from "prop-types";

import Header from "../header";
import { container } from "./styles.module.css";

const Layout = ({ siteTitle, children }) => {
  return (
    <div>
      <Header title={siteTitle} />
      <div className={container}>
        <main>{children[0]}</main>
      </div>

      {children[1]}

      <footer>
        {`© ${new Date().getFullYear()} Copyright:`}&nbsp;
        <a href="#">DonLoTech.com</a>
      </footer>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
