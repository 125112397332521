import * as React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import { StaticImage } from "gatsby-plugin-image";

import { container, info, innercontainer, demo } from "./styles.module.css";
import { Container } from "@material-ui/core";
import scrollTo from "gatsby-plugin-smoothscroll";

const informationItems = [
  [
    "Easy to use",
    "Using the same design principles as popular mobile apps, the workforce can be up to speed within a few minutes.",
  ],
  [
    "Customisable",
    "Built modularly, it is adaptable to suit your unique business needs.",
  ],

  [
    "Quicker inspections",
    "Mechanics can fill in reports in a fraction of the time, due to smart auto-completition.",
  ],
  [
    "Fleet monitoring",
    "Managers can keep track of full vehicle history including all reports, warranties and parts fitted.",
  ],
];

const InfoItem = ({ item }) => (
  <li>
    <Typography variant="h6" component="h2" display="inline">
      {item[0]}
      {" - "}
    </Typography>
    <Typography variant="body2" display="inline">
      {item[1]}
    </Typography>
  </li>
);

const Intro = () => {
  return (
    <div className={container}>
      <Container maxWidth="lg" className={innercontainer}>
        <section className={info}>
          <Typography variant="h2" component="h1">
            Garage fleet management
          </Typography>
          <Typography variant="body1">
            Designed with mechanics and garage managers, FleetViper offers a
            software solution to increase productivity without hinderance.
          </Typography>
          <ul>
            {informationItems.map((item) => (
              <InfoItem item={item} key={item[0]} />
            ))}
          </ul>

          <Button
            variant="contained"
            color="secondary"
            size="large"
            onClick={() => scrollTo("#contact")}
          >
            Request demo
          </Button>
        </section>
        <StaticImage
          objectFit="contain"
          className={demo}
          alt="FleetViper on desktop and handheld devices"
          src="../../images/demo.png"
        />
      </Container>
    </div>
  );
};

Intro.propTypes = {};

export default Intro;
