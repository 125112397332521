import * as React from "react";
import Layout from "../components/layout";
import { StaticImage } from "gatsby-plugin-image";
import Intro from "../components/intro";
import { ThemeProvider } from "@material-ui/core";
import theme from "../components/theme";
import Contact from "../components/contact";
import { Helmet } from "react-helmet";

const IndexPage = () => {
  return (
    <ThemeProvider theme={theme}>
      <Helmet
        htmlAttributes={{
          lang: "en",
        }}
        title="FleetViper"
      >
        <meta charSet="utf-8" />
        <meta name="description" content="Garage fleet management software" />
      </Helmet>
      <Layout>
        <Intro />
        <Contact />
      </Layout>
    </ThemeProvider>
  );
};

export default IndexPage;
